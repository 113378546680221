import React, { useState } from "react";
import "./style.css";
import BNB from "../../images/bnb.png";
import logo from "../../images/Fnllogo.png";
import { useWeb3React } from "@web3-react/core";
import { Injected } from "../../Helpers/Injected";
import Abi from "../../Helpers/abi.json";

const isValid = (regex) => (input) => regex.test(input);
const numberRegex = /^\d*\.?\d*$/;
const isValidNumber = isValid(numberRegex);

const MainSection = () => {

  const { active, account, library, connector, activate, diactivate } = useWeb3React();

  const cAddress = "0xE7951Deb4724214692c4d96D36020225de190d9b";

  const [enable, setEnable] = useState(false);

  const gartVal = 10000;
  const [data, setData] = useState({
    bnb: "",
    gart: ""
  });

  const processBuy = async () => {
    if(!data.bnb || !data.gart) {
      alert('Please enter correct value.')
      return;
    }
    setEnable(true);
    
    const contract = await new library.eth.Contract(Abi, cAddress);
    let bnbValue = await library.utils.toWei(data.bnb, "ether");
    await contract.methods.buyToken().send({from: account, value:bnbValue})
      .on('error', function(error){
        setEnable(false)
        alert(error.message)
      }).then(function(receipt){
        setEnable(false);
        alert(`${data.gart} GET tokens has been sent to your wallet.`);
        setData({bnb:"",gart:""})
      })
    
  }

  const buyToken = async () => {
    if(active){
      processBuy();
    } else {
      await activate(Injected);
    }
  }


  return (
    <div className="main-section">
      <p className="headings">Presale for GET Begins..</p>
      <p className="headings">Early access to GET tokens</p>
      <div className="main-section-form">
        <p>1 BNB = {gartVal} GET</p>
        <p className="mgtp">Pay with</p>
        <div className="form-group">
          <input
            type="text"
            value={data.bnb}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                bnb: val,
                gart: val * gartVal,
              });
            }}
          />
          <div>
            <img src={BNB} alt="snk" style={{ marginRight: "10px" }} />
            <p>BNB</p>
          </div>
        </div>
        <p className="mgtp">You will get</p>
        <div className="form-group">
          <input
            type="text"
            value={data.gart}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                gart: val,
                bmb: val / gartVal,
              });
            }}
          />
          <div>
            <img src={logo} alt="snk" />
            <p>GET</p>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          <a
            href="www.google.com"
            style={{ color: "#000000" }}
            className="bold"
          >
            
          </a>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          <button className="buy" onClick={buyToken} disabled={enable}>{account?"Buy":"Connect"}</button>
        </div>

        <div className="smart">
          <i class="fa fa-lock" aria-hidden="true"></i>
          <p>100% Secure smart contract</p>
        </div>
      </div>
      <div className="link">
        <a href="www.google.com">GET Token contract</a>
      </div>
      <div className="link">
        <a href="www.google.com">GET presale smart contract</a>
      </div>
      <div className="link">
        <a href="www.google.com">How to add GET token to your wallet</a>
      </div>
    </div>
    
  );
};

export default MainSection;
