import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-images">
        <div
          className="footer-block"
          onClick={() => {
            window.open("https://www.google.com", "_");
          }}
        >
          <i class="fab fa-3x fa-youtube" aria-hidden="true"></i>
          <div className="footer-text">
            <p>YOUTUBE</p>
            <h3>GET</h3>
          </div>
        </div>
        <div
          className="footer-block"
          onClick={() => {
            window.open("https://www.google.com", "_");
          }}
        >
          <i class="fab fa-3x fa-twitter" aria-hidden="true"></i>
          <div className="footer-text">
            <p>TWITTER</p>
            <h3>GET</h3>
          </div>
        </div>
        <div
          className="footer-block"
          onClick={() => {
            window.open("https://www.google.com", "_");
          }}
        >
          <i class="fab fa-3x fa-telegram" aria-hidden="true"></i>
          <div className="footer-text">
            <p>TELEGRAM</p>
            <h3>GET</h3>
          </div>
        </div>
        <div
          className="footer-block"
          onClick={() => {
            window.open("https://www.google.com", "_");
          }}
        >
          <i class="fab fa-3x fa-discord"></i>
          <div className="footer-text">
            <p>INSTAGRAM</p>
            <h3>GET</h3>
          </div>
        </div>
      </div>
      <p className="rights">All Rights reserved. greenenergytokens.com</p>
    </div>
  );
};

export default Footer;
