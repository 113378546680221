import React from 'react';
import Footer from './components/footer';
import Header from './components/header';
import MainSection from './components/main';
import './App.css';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';




const getLibrary = (provider) => {
  return new Web3(provider);
}

const App = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div>
        <Header />
        <MainSection />
        <Footer />
      </div>
    </Web3ReactProvider>
  );
}

export default App;
